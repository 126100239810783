<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24">
            <a-form-item label="状态">
              <a-select
                label-in-value
                @search="handleTagSearch"
                @change="handleTagSearchChange"
                placeholder="请选择"
                allowClear
              >
                <a-select-option v-for="(val, key) in dataSource_tag" :key="key" :tt="val">
                  {{ val }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handlePush(record)">推送</a>
          <a-divider type="vertical"/>
          <a @click="handleWatch(record)">查看</a>
          <a-divider type="vertical"/>
          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelet(record)"
          >
            <a>删除</a>
          </a-popconfirm>
        </template>
      </span>
    </s-table>

    <customs-order-form
      ref="CustomsOrderForm"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
  </div>
</template>

<script>
import moment from 'moment'
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'

import {
  customs_order_delete,
  customs_order_push, customs_payment_check_list
} from '@/api/customs_order'
import CustomsOrderForm from '@/views/b-customs_order/modules/CustomsOrderForm'

export default {
  name: 'TableList',
  components: {
    CustomsOrderForm,
    STable
  },
  data () {
    return {
      tag_value: '',
      dataSource_tag: { },
      loading: false,
      confirmLoading: false,
      current_bill_id: 0,
      // 创建窗口控制
      visible: false,
      mdl: {},
      payconfirmLoading: false,
      // 创建窗口控制
      payvisible: false,
      paymdl: {},
      dictionaries: Dictionaries,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '订单号',
          dataIndex: 'order_no'
        },
        {
          title: '状态',
          dataIndex: 'status',
          customRender: (text) => this.$Dictionaries.CustomsPaymentCheckStatus[text]
        },
        {
          title: '创建时间',
          customRender: (text) => text || '-',
          dataIndex: 'create_time'
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return customs_payment_check_list(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  created () {
    this.dataSource_tag = this.$Dictionaries.CustomsPaymentCheckStatus
  },
  methods: {
    handleTagSearch (value) {
    },
    handleTagSearchChange (value) {
      if (value === undefined) {
        this.queryParam.status = undefined
      } else {
        this.queryParam.status = value.key
      }
      // this.tag_value = value
    },
    time (dates, dateStrings) {
      this.queryParam.start_time = dateStrings[0]
      this.queryParam.end_time = dateStrings[1]
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    },
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: { show: true, clear: () => { this.selectedRowKeys = [] } },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handlePush (record) {
      customs_order_push(record, record.id).then(res => {
        if (res.code === 1000) {
          this.$message.success('推送成功')
          this.$refs.table.refresh()
        }
      })
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleWatch (record) {
      this.$router.push('/e/customs_order/' + record.id + '/goods')
    },
    handleEdit (record) {
      this.$emit('onEdit', record)
    },
    handleUpdate (record) {
      this.current_bill_id = record.id
      this.payvisible = true
    },
    handleDelet (record) {
      customs_order_delete(record.id).then((res) => {
        this.$refs.table.refresh()
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          // exchange_rate_create(values).then(res => {
          //     this.visible = false
          //     this.confirmLoading = false
          //     // 重置表单数据
          //     form.resetFields()
          //     // 刷新表格
          //     this.$refs.table.refresh()
          //   })
          //   .catch((err) => {
          //     console.log(err)
          //     this.confirmLoading = false
          //   })
        } else {
          this.confirmLoading = false
        }
      })
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },

    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
